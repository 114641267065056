import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <section className='honbun'>
      <p>{`【補足１】`}</p>
      <p>{`動画では、リバーブを「マスターインサートの１段目」に入れる理由がわかりにくいので、補足します。`}</p>
      <p>{`このリバーブは、後でマスタートラックから外して、FXチャンネルに移動させるものです。つまり、本来はマスタートラックより「前」にあるはずですね。別の言い方をすると、「`}<strong parentName="p">{`マスターのインサートより前`}</strong>{`」です。`}<br parentName="p"></br>{`
`}{`例えば、マスターのインサートに「１段目：コンプ」「２段目：リミッター」が入っているとします。すると、リバーブはこの２つより前にあります。`}<br parentName="p"></br>{`
`}{`この順番を再現するために、リバーブはマスターインサートの１段目に入れます。これなら、`}<strong parentName="p">{`他のマスターインサートより「前」`}</strong>{`になり、正しい順序になります。`}</p>
      <p>{`しかし、もしリバーブを３段目に入れてしまうと、マスターインサートの１〜２段目によって、リバーブの前に音が加工されてしまいます。この加工は、本来リバーブの「後」にあるはずなので、「あるはずのない加工をされた音」にリバーブがかかってしまいます。`}<strong parentName="p">{`これだと、本来とは違う音になってしまいます。`}</strong><br parentName="p"></br>{`
`}{`例えるなら、「ご飯をチンする→卵を乗せる」のはずが、「卵を乗せる→ご飯をチンする」になって、爆発してしまうようなものですね。`}</p>
      <p>{`ちなみに、マスターチャンネルでリバーブを設定するときは、リバーブの「Mix」を`}<strong parentName="p">{`20％`}</strong>{`にして作業し、FXチャンネルに移動させたら`}<strong parentName="p">{`100％`}</strong>{`に戻します。`}</p>
      <hr></hr>
      <p><strong parentName="p">{`【補足２】※　重要　※`}<br parentName="strong"></br>
          {`カリキュラムのメールにも書きますが、重要なのでこちらにも載せておきます。`}</strong></p>
      <p>{`ここまでで「大きさ」から「エコー」までの５つの要素は、一通りすべて終わりました。今後、実際にミックスするときは、この順番通りに`}</p>
      <ol>
        <li parentName="ol">{`全ての楽器の「大きさ」を整える`}<br parentName="li"></br>
          {`＝ボリューム`}</li>
        <li parentName="ol">{`全ての楽器を「配置」する`}<br parentName="li"></br>
          {`＝パン`}</li>
        <li parentName="ol">{`全ての楽器の「強弱」を整える`}<br parentName="li"></br>
          {`＝コンプ`}</li>
        <li parentName="ol">{`全ての楽器の「高さ別の大きさ」を整える`}<br parentName="li"></br>
          {`＝EQ`}</li>
        <li parentName="ol">{`全ての楽器に「エコー」をつける`}<br parentName="li"></br>
          {`＝リバーブ`}</li>
      </ol>
      <p>{`という流れになります。`}<br parentName="p"></br>{`
`}{`コンプ以降は、楽器によっては必要ない場合もありますので、その場合は無理に使わなくても大丈夫です。「使わない」ということも、大切な選択肢です。`}</p>
      <p>{`さて、教材の最初に、「どうしてこの順番でないといけないのかは、最後に話します」と言いましたね。その理由を説明しましょう。`}</p>
      <ol>
        <li parentName="ol">
          <p parentName="li">{`最初に聴いた通り、「大きさ」が綺麗に整理されていないと、もう何が何だかわかりませんね。`}<strong parentName="p">{`これでは、なんの作業もできない`}</strong>{`ので、初めにやります。これが、「大きさ」が一番最初である理由です。`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`では、なぜ２番目が「配置」なのでしょうか？、それは、`}<strong parentName="p">{`きちんと配置するだけで、楽器同士の音がお互いに邪魔し合うことはほとんどなくなくなるから`}</strong>{`です。`}<br parentName="p"></br>{`
`}{`たいていの教材では`}<br parentName="p"></br>{`
`}{`「楽器の音が邪魔し合う場合は、EQを使って、かぶっている帯域を削りましょう」`}<br parentName="p"></br>{`
`}{`とかアホなことを書いていますが、そんなことをしなくても、ほとんどの場合は配置を重ならないようにするだけで解決します。ですから、`}<strong parentName="p">{`まずは配置を先に試す方がはるかにスマート`}</strong>{`です。`}<br parentName="p"></br>{`
`}{`僕たちが苦しむ時というのは、ムダなことをやっている時です。`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`次に、「EQが先か、コンプが先か」という、これまたナンセンスな議論がよくあります。これも、誰が何と言おうと「コンプが先」、つまり「強弱が先」です。`}<br parentName="p"></br>{`
`}{`なぜなら、「EQ」すなわち「高さ別の大きさ」は、文字通り「大きさ」を変える作業でしたね。ところが、「大きさ」をいじりたいのに、`}<strong parentName="p">{`楽器の「大きさそのもの」がガクガクと上下している状態では、まともにいじれるはずがありません。`}</strong><br parentName="p"></br>{`
`}{`つまり、先に「強弱」を整えておかないと、正しい判断ができません。正しい判断ができないのだから、何をやっても正しい結果になりません。当たり前です。`}<br parentName="p"></br>{`
`}{`順番を間違えると、こういうムダな作業ばかりになってしまいます。ですから、誰が何というと、コンプ、すなわち強弱が先です。`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`最後に、なぜEQが先で、リバーブが後なのでしょうか？`}<br parentName="p"></br>{`
`}{`リバーブというのは、音を響かせるものでしたね。響くということは、音が後ろに「伸びる」、つまり「`}<strong parentName="p">{`鳴りっぱなしになる`}</strong>{`」ということです。`}<br parentName="p"></br>{`
`}{`では、もしEQをせずに、低音が大きいままのボーカルが「鳴りっぱなし」になると、どう聞こえるでしょうか？低音は「ちょっと大きめ」のまま「鳴りっぱなし」になりますね。すると、`}<strong parentName="p">{`低音がめちゃめちゃ鳴っているように感じてしまう`}</strong>{`のです。`}<br parentName="p"></br>{`
`}{`それを聴いて、僕たちはこう思います。`}<br parentName="p"></br>{`
`}{`「EQで、`}<strong parentName="p">{`リバーブの低音を`}</strong>{`削らなあかん！」`}<br parentName="p"></br>{`
`}{`そして、リバーブの低音を、EQで削るでしょう。`}<br parentName="p"></br>{`
`}{`しかし、いくら削っても音はスッキリしません。`}<br parentName="p"></br>{`
`}{`「あれえ？おかしいなあ？なんでや？？？」`}<br parentName="p"></br>{`
`}{`なぜなら、そもそもボーカルの低音が太っているからです。`}<br parentName="p"></br>{`
`}{`「そうか、ボーカルを削らなあかんのか…」`}<br parentName="p"></br>{`
`}{`そこで、今度はボーカルの低音を削るでしょう。`}<br parentName="p"></br>{`
`}{`しかし、今度は変に音が痩せて聞こえます。`}<br parentName="p"></br>{`
`}{`「あれえ？？なんでや？？？」`}<br parentName="p"></br>{`
`}{`だって、`}<strong parentName="p">{`リバーブの低音を削っている`}</strong>{`のですから。`}<br parentName="p"></br>{`
`}{`そして、再びリバーブのEQをやり直すことになります。`}<br parentName="p"></br>{`
`}{`「くそお、こっちも直さなあかんのか…」`}<br parentName="p"></br>{`
`}<strong parentName="p">{`ここまでで、一体どれだけの時間と手間と、労力がかかったでしょうか。`}</strong><br parentName="p"></br>{`
`}{`これらはすべて、完全にムダな作業です。繰り返しますが、僕たちがミックスで苦しむ時というのは、ムダなことをしている時です。`}<br parentName="p"></br>{`
`}{`だから、リバーブの前には、先にEQをしておく必要があるのです。`}</p>
        </li>
      </ol>
      <p>{`このように、５つに手順が決まっているのは、`}<strong parentName="p">{`ムダな作業を減らすため、勘違いが起こらないようにするため`}</strong>{`なのです。だから、`}</p>
      <ol>
        <li parentName="ol">{`大きさ（ボリューム）`}</li>
        <li parentName="ol">{`配置（パン）`}</li>
        <li parentName="ol">{`強弱（コンプ）`}</li>
        <li parentName="ol">{`高さごとの大きさ（EQ）`}</li>
        <li parentName="ol">{`エコー（リバーブ）`}</li>
      </ol>
      <p>{`の順番は、何があっても守る必要があります。これこそが、`}<strong parentName="p">{`この教材の中で最も大切な部分`}</strong>{`です。`}<br parentName="p"></br>{`
`}{`逆にいえば、これさえ守っておけば、もう迷子になることはありません。機械的に、順序通りにやるだけで、勝手にミックスは出来上がります。`}<br parentName="p"></br>{`
`}{`また、順番が決まっているので、いま自分がどこにいるのか、はっきりわかります。「この後何をしたらいいのか」「後どれぐらいで完成するのか」がわかるので、不安ならず、ストレスもありません。`}</p>
      <p>{`迷走しがちなミックスにおいて、これほど心強いことはないでしょう。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      